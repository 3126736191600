/* настройки брендв */
.brand-avatar-container {
  position: relative;
  width: 150px;
  height: 150px;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.brand-avatar-container:hover {
  transform: scale(1.05);
}

.brand-avatar-container.selected {
  transform: scale(1.1);
}

.brand-avatar {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  height: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.brand-avatar img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.brand-avatar-container svg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.brand-avatar-container {
  width: 112px;
  height: 112px;
}  
/* настройки брендв */

@keyframes confetti-fall {
  0% { transform: translateY(-100vh) rotate(0deg); }
  100% { transform: translateY(100vh) rotate(360deg); }
}

.animate-confetti {
  position: absolute;
  animation: confetti-fall linear infinite;
}

.single-select-container {
  max-width: 100%;
  overflow: hidden;
}

.single-select-container .selected-value {
  width: 24px;
  height: 16px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.single-select-container .selected-value img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}


/* Нижнее меню */
.fixed {
  position: fixed;
}

.bottom-0 {
  bottom: 0;
}

.left-0 {
  left: 0;
}

.right-0 {
  right: 0;
}

.border-t {
  border-top-width: 1px;
}

.pb-16 {
  padding-bottom: 4rem;
}
/* Нижнее меню */

/* Стили для главного меню */
.main-menu {
  background-color: rgb(0, 0, 0);
}

.main-menu-item {
  background-color: transparent !important;
  transition: all 0.3s ease;
}

.main-menu-item:hover, .main-menu-item:focus {
  background-color: transparent !important;
}

.main-menu-item:hover .menu-icon,
.main-menu-item:hover .menu-text,
.main-menu-item:focus .menu-icon,
.main-menu-item:focus .menu-text {
  color: #ffffff;
}

.main-menu-item.active {
  color: #e0ff89;
  filter: drop-shadow(0 0 5px #e0ff89);
}

.main-menu-item.active .menu-icon,
.main-menu-item.active .menu-text {
  color: #e0ff89;
  filter: drop-shadow(0 0 5px #e0ff89);
}
/* Стили для главного меню */

/* скрытие скролла в меню */
.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

.scrollbar-hide {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.overflow-y-auto {
    overflow-y: auto;
    padding-bottom: 20px;
}
/* скрытие скролла в меню */