@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;900&display=swap');

.landing-page {
  min-height: 100vh;
  font-family: 'Montserrat', sans-serif;
  display: flex;
  flex-direction: column;
  position: relative;
  transition: background-color 0.5s ease;
}

.landing-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 50px;
  z-index: 2;
}

.landing-logo {
  font-size: 24px;
  font-weight: 600;
  transition: color 0.5s ease;
}

.landing-auth {
  display: flex;
  gap: 30px;
}

.landing-auth-btn {
  position: relative;
  padding: 12px 32px;
  font-size: 16px;
  font-weight: 600;
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 1px;
  border: none;
  background: transparent;
  filter: url(#goo);
  transition: all 0.3s ease;
  z-index: 1;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.landing-auth-btn::before {
  content: '';
  position: absolute;
  top: -10px;
  left: -15px;
  right: -15px;
  bottom: -10px;
  background: var(--button-bg-color);
  z-index: 0;
  /* Скорость анимации можно менять здесь (сейчас 8 секунд) */
  animation: blobEffect 8s infinite;
}

@keyframes blobEffect {
  /* Здесь каждый процент это ключевой кадр анимации
     Числа в border-radius это и есть форма капли
     Первое число влияет на верхний выступ
     Второе на правый
     Третье на нижний
     Четвертое на левый
     После слеша то же самое, но для углов */
  0% {
    border-radius: 0% 90% 0% 70% / 60% 0% 70% 0%;
  }
  20% {
    border-radius: 90% 0% 80% 0% / 0% 80% 0% 80%;
  }
  40% {
    border-radius: 0% 70% 0% 80% / 90% 0% 90% 0%;
  }
  60% {
    border-radius: 70% 0% 90% 0% / 0% 90% 0% 90%;
  }
  80% {
    border-radius: 0% 80% 0% 90% / 80% 0% 80% 0%;
  }
  100% {
    border-radius: 0% 90% 0% 70% / 60% 0% 70% 0%;
  }
}

/* Стили для текста */
.landing-auth-btn span {
  position: relative;
  z-index: 2;
  color: var(--button-text-color);
}

/* Эффект при наведении */
.landing-auth-btn:hover::before {
  animation-duration: 4s;
}

.landing-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 20px;
}

.title-container {
  position: relative;
  display: inline-block;
}

.type-label {
  position: absolute;
  top: -50px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 2px;
  padding: 8px 20px;
  border: 2px solid currentColor;
  border-radius: 20px;
  transition: all 0.5s ease;
  background-color: transparent;
  z-index: 10;
  white-space: nowrap;
}

.type-label::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 20px;
  background-color: currentColor;
  opacity: 0.1;
  z-index: -1;
}

.glitch {
  font-size: 90px;
  font-weight: 900;
  text-transform: uppercase;
  position: relative;
  text-shadow: 0.05em 0 0 #00fffc, -0.03em -0.04em 0 #fc00ff,
                0.025em 0.04em 0 #fffc00;
  animation: glitch 725ms infinite;
  cursor: pointer;
  transition: color 0.5s ease;
}

.glitch span {
  position: absolute;
  top: 0;
  left: 0;
}

.glitch::before,
.glitch::after {
  content: attr(data-text);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

/* Эффект глитча только при наведении */
.glitch:hover::before {
  opacity: 0.75;
  animation: glitch-before 500ms linear infinite;
  text-shadow: 0.05em 0 0 #00fffc;
  clip-path: polygon(0 0, 100% 0, 100% 45%, 0 45%);
}

.glitch:hover::after {
  opacity: 0.75;
  animation: glitch-after 500ms linear infinite;
  text-shadow: -0.05em 0 0 #fc00ff;
  clip-path: polygon(0 60%, 100% 60%, 100% 100%, 0 100%);
}

@keyframes glitch {
  0% {
    text-shadow: 0.05em 0 0 #00fffc, -0.03em -0.04em 0 #fc00ff,
                 0.025em 0.04em 0 #fffc00;
  }
  15% {
    text-shadow: 0.05em 0 0 #00fffc, -0.03em -0.04em 0 #fc00ff,
                 0.025em 0.04em 0 #fffc00;
  }
  16% {
    text-shadow: -0.05em -0.025em 0 #00fffc, 0.025em 0.035em 0 #fc00ff,
                 -0.05em -0.05em 0 #fffc00;
  }
  49% {
    text-shadow: -0.05em -0.025em 0 #00fffc, 0.025em 0.035em 0 #fc00ff,
                 -0.05em -0.05em 0 #fffc00;
  }
  50% {
    text-shadow: 0.05em 0.035em 0 #00fffc, 0.03em 0 0 #fc00ff,
                 0 -0.04em 0 #fffc00;
  }
  99% {
    text-shadow: 0.05em 0.035em 0 #00fffc, 0.03em 0 0 #fc00ff,
                 0 -0.04em 0 #fffc00;
  }
  100% {
    text-shadow: -0.05em 0 0 #00fffc, -0.025em -0.04em 0 #fc00ff,
                 -0.04em -0.025em 0 #fffc00;
  }
}

@keyframes glitch-before {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(2px);
  }
  50% {
    transform: translateX(-2px);
  }
  75% {
    transform: translateX(1px);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes glitch-after {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(-2px);
  }
  50% {
    transform: translateX(2px);
  }
  75% {
    transform: translateX(-1px);
  }
  100% {
    transform: translateX(0);
  }
}

.landing-subtitle {
  font-size: 24px;
  margin-top: 20px;
  font-weight: 400;
  max-width: 600px;
  user-select: none;
  transition: color 0.5s ease;
}

.landing-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 50px;
  z-index: 2;
}

.landing-footer-left {
  display: flex;
  gap: 10px;
}

.landing-footer-link {
  text-decoration: none;
  transition: opacity 0.3s ease, color 0.5s ease;
}

.landing-footer-link:hover {
  opacity: 0.7;
}

.landing-footer-separator {
  transition: color 0.5s ease;
}

.landing-footer-right {
  transition: color 0.5s ease;
}

@media (max-width: 768px) {
  .type-label {
    top: -40px;
    font-size: 12px;
    padding: 6px 15px;
  }

  .glitch {
    font-size: 60px;
  }
  
  .landing-subtitle {
    font-size: 18px;
  }
  
  .landing-top, 
  .landing-footer {
    padding: 20px;
  }
  
  .landing-auth {
    gap: 15px;
  }
  
  .landing-auth-btn {
    padding: 8px 15px;
    font-size: 14px;
  }

  /* Добавляем стили для адаптивного футера */
  .landing-footer {
    flex-direction: column;
    gap: 15px;
    text-align: center;
  }

  .landing-footer-left {
    justify-content: center;
  }

  .landing-footer-right {
    font-size: 14px;
  }
}

.type-label {
  animation: fadeInDown 0.5s ease-out;
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translate(-50%, -20px);
  }
  to {
    opacity: 1;
    transform: translate(-50%, 0);
  }
}

.title-container:hover .type-label {
  transform: translateX(-50%) scale(1.05);
}